<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">Operasional</li>          
            <li class="breadcrumb-item active" aria-current="page">Antar Barang</li>
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>
            <!-- <template v-if="anyPrevData == 0"> -->
              <div class="col-xl-8 col-lg-6 mx-auto">
                <div class="auth-form-light text-left p-5">
                  <div class="card pb-4">
                    <div class="brand-logo text-center">
                      <img class="pt-5" src="@/assets/images/kebut/XMLID_1147_.png">
                    </div>
                    <div class="row pl-4">
                      <h6 class="font-weight-bold col-lg-12">Berat Maksimal Paket : 25kg</h6>
                      <h6 class="font-weight-bold col-lg-12">Batas Ukuran Paket : 60x60x60</h6>
                      <h6 class="font-weight-bold col-lg-12">Gratis Waktu Tunggu : {{ `${txt_load_time}` }}</h6>
                    </div>																											        
                  </div>

                  <h4 class="pt-3 text-center">Detail Pengambilan Barang</h4>
                  <form class="pt-3">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <b-row>
                            <b-col lg="6"><label>Tanggal Kirim</label></b-col>
                          </b-row>
                          <date-picker
                            :disabled-date="disabledBeforeToday"
                            :default-value="new Date()"                  
                            v-model="form.date_sender"
                            format="YYYY-MM-DD"
                            date-format="YYYY-MM-DD"
                            placeholder="Tanggal Kirim"
                            type="date"
                          ></date-picker>
                        </div>
                        <div class="form-group">
                          <b-row>
                            <b-col lg="6"><label>Jam Kirim</label></b-col>
                          </b-row>
                          <vue-timepicker v-model="form.time_sender" format="HH:mm:ss"></vue-timepicker>                        
                        </div>											
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <b-row class="mt-2">
                            <b-col lg="6"><label>Pengirim</label></b-col>
                          </b-row>
                          <v-select v-model="optSelect" :options="dataOption1"></v-select>
                        </div>
                      </div>										
                    </div>
                    <!-- <div class="form-group"> -->
                      <!-- <b-row class="mt-2">
                        <div class="col-lg-9">&nbsp;</div>
                        <div class="col-lg-3">
                          <b-button ref="containerButton" class="col-lg-12 w-100 pull-right btn btn-block btn-gradient-primary btn-sm font-weight-medium" style="background-color:#d2ddf7;margin-left: 5px;" variant="info" @click="onNext"><i class="fa fa-edit opacity-50"></i> Edit</b-button>
                        </div>
                      </b-row> -->
                      <b-row class="mt-2">
                        <b-col lg="6"><label>Alamat Pengirim</label></b-col>
                      </b-row>                    
                      <textarea v-model="form.address_sender"  class="form-control form-control-lg"></textarea>
                    <!-- </div> -->
                    <div class="form-group">
                      <b-row>
                        <b-col lg="6"><label>Detail Lokasi</label></b-col>
                      </b-row>                    
                      <input type="text" v-model="form.locate_detail_sender" class="form-control form-control-lg" placeholder="(contoh patokan)">
                    </div>
                    <div class="form-group">
                      <b-row>
                        <b-col lg="6"><label>Nama Pengirim</label></b-col>
                      </b-row>                    
                      <input type="text" v-model="form.name_sender" class="form-control form-control-lg" placeholder="(contoh patokan)">
                    </div>
                    <div class="form-group">
                      <b-row>
                        <b-col lg="6"><label>Nomor Telepon</label></b-col>
                      </b-row>                    
                      <input type="number" v-model="form.telp_sender" class="form-control form-control-lg" placeholder="(contoh patokan)">
                    </div>
                    <div class="form-group">
                      <b-row>
                        <b-col lg="6"><label>Pesan untuk Driver</label></b-col>
                      </b-row>                    
                      <textarea v-model="form.note_sender"  class="form-control form-control-lg"></textarea>
                    </div>                                                      
                    <div class="mt-3">
                      <template v-if="alert.status != 0">
                        <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                      </template>
                      <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Lanjut</b-button>                    
                      <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                    </div>
                  </form>
                </div>
              </div>
            <!-- </template>
            <template v-else>
              <div class="col-xl-9 col-lg-6 mx-auto">
                <div class="auth-form-light text-left p-5">
                  <div class="card pb-4">
                    <div class="brand-logo text-center">
                      <img class="pt-5" src="@/assets/images/kebut/XMLID_1147_.png">
                    </div>
                    <div class="row pl-4">
                      <h6 class="font-weight-bold col-lg-12 text-center">Ups, kamu ada transaksi yang belum diselesaikan.</h6>
                    </div>																											        
                  </div>
                </div>
              </div>    
              
              <div class="col-xl-9 col-lg-6 mx-auto pt-1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Recent Tickets</h4>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Pengirim</th>
                            <th>Penerima</th>
                            <th>Paket</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in prevData" :key="index">
                            <td>{{ item.name_sender }}</td>
                            <td>{{ item.name_recipient }}</td>
                            <td>
                              <label class="badge badge-success">{{ item.desc_package }}</label>
                            </td>
                            <td>
                              <router-link :to="item.route"><span class="mdi mdi-arrow-right font-weight-medium" style="font-size: 25px;"></span></router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </template> -->
          </div>
        </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      errorMassage:0,
      dataTime: [],
      txt_load_time: '',
      form:{
        route: '/operasional/antar-barang/',
        route_title: 'pengambilan_barang',        
        uuid_type_customers: '',        
        date_sender: '',
        time_sender: '',        
        address_sender: '',
        locate_detail_sender: '',
        name_sender: '',
        telp_sender: '',
        note_sender: '',
        uuid_m_load_times: '',
        created_by: localStorage.getItem("uuid"),
      },
      anyPrevData : 0,
      prevData : [],
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataOption1: [],
      optSelect: '',      
    }
  },
  methods:{
    disabledBeforeTodayAndAfterAWeek: (date) => packageGlobals.disabledBeforeTodayAndAfterAWeek(date),
    disabledBeforeToday: (date) => packageGlobals.disabledBeforeToday(date),
    activeSettingLoadTime: function() {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/load-time/active`,packageGlobals.configAxios)
			.then((response) => {
        this.dataTime = response.data.result[0]
        this.txt_load_time = `${this.dataTime.value} ${this.dataTime.time_unit_name}`
        this.form.uuid_m_load_times = this.dataTime.uuid_load_times
        console.log(this.form.uuid_m_load_times);
			})	      
    },    
    getTypeCustomer: function (search, page, limit) {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/reference/type-customer?search=${search}&page=${page}&limit=${limit}`,packageGlobals.configAxios)
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
			})							
    },
    getPrevData: function() {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/previous-data/${this.form.created_by}`,packageGlobals.configAxios)
			.then((response) => {
        let res = response.data.result
        this.anyPrevData = (res.length == 0) ? 0 : 1
        // console.log(res.length);        
        // console.log(this.anyPrevData);
        this.prevData = res
			})      
    },
    // onNext(evt) {
    onNext() {
      this.form.uuid_type_customers = this.optSelect['id']
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "POST"
      axios.post(`${packageStore.mainApi}/api/web/operational/antar-barang`,this.form)
      .then((response) => {
        console.log(response.data.data[0].uuid);
        this.$router.push(`/operasional/antar-barang/penerima-paket/${response.data.data[0].uuid}`)        
      })        
    }
  },
  mounted() {
    this.getPrevData()
    this.activeSettingLoadTime()    
		this.getTypeCustomer('-', 1, 999)		    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}
</style>